.Main-Container *{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.Main-Container html {
  font-family: "Montserrat", sans-serif;
}
.Main-Container{
  background: #eee;
  /* background-color: #eeeeee; */
    background-image: url("https://www.transparenttextures.com/patterns/brick-wall-dark.png");
    /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
  padding: 0 16px;
  height: 600px;
}

.Main-Container header {
  background-color: #C49F4F;
  margin: auto -16px;
  padding: 16px 32px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}

.Main-Container header h1 {
  color: #fff;
  font-family: "McLaren", cursive;
  font-weight: 200;
}

.Main-Container footer {
  position: absolute;
  text-align: center;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
}

.Main-Container footer p {
  color: #ccc;
}
.Main-Container .note {
  background: #fff;
  border-radius: 7px;
  box-shadow: 0 2px 5px #ccc;
  padding: 10px;
  width: 240px;
  margin: 16px;
  float: left;
}
.Main-Container .note h1 {
  font-size: 1.1em;
  margin-bottom: 6px;
}
.Main-Container .note p {
  font-size: 1.1em;
  margin-bottom: 10px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.Main-Container .note button {
  position: relative;
  float: right;
  margin-right: 10px;
  color: #C49F4F;
  border: none;
  width: 36px;
  height: 36px;
  cursor: pointer;
  outline: none;
}

.Main-Container form.create-note {
  position: relative;
  width: 480px;
  margin: 30px auto 20px auto;
  background: #fff;
  padding: 15px;
  border-radius: 7px;
  box-shadow: 0 1px 5px rgb(138, 137, 137);
}
.Main-Container form.create-note input,
.Main-Container form.create-note textarea {
  width: 100%;
  border: none;
  padding: 4px;
  outline: none;
  font-size: 1.2em;
  font-family: inherit;
  resize: none;
}
.Main-Container form.create-note button {
  position: absolute;
  right: 18px;
  bottom: -18px;
  background: #C49F4F;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  outline: none;
}
